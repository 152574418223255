.ctas button {
    appearance: none;
    background: transparent;
    border-radius: 128px;
    height: 48px;
    padding: 0 20px;
    border: none;
    border: 1px solid transparent;
    transition:
      background 0.2s,
      color 0.2s,
      border-color 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }
  
  button.primary {
    background: var(--foreground);
    color: var(--background);
    gap: 8px;
  }
  
  button.secondary {
    border-color: var(--gray-alpha-200);
    min-width: 180px;
  }